<template>
  <div class="box">
    <div class="noReward">
      <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/later2x.png" alt="">
      <div class="msg">{{ laterAgainMsg }}</div>
    </div>
    <div class="commBtn" @click="receiveFn">好的</div>
  </div>
</template>
<script>
export default {
  props: {
    laterAgainMsg: {
      type: String,
      default: '活动太火爆了，请稍后再来'
    },
  },
  data() {
    return {
    }
  },
  created() {
  },

  methods: {
    receiveFn() {
      this.$emit('rewarnFn', '3')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  .addCalendar {
    position: relative;

    img {
      width: 159px;
      height: 159px;
      position: absolute;
      top: 0;
    }
  }

  .noReward {
    margin: 0 0 15px 0px;
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 219px;
      height: 219px;
      margin-bottom: 10px;
    }

    .msg {
      padding: 0 15px;
      white-space: pre-wrap;
      text-align: center;
      line-height: 27px;
    }
  }

  .commBtn {
    margin: 30px auto 30px;
    width: 230px;
    height: 56px;
    // padding:14px 0 0 136px ;
    box-sizing: border-box;
    background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/commBtn.png");
    background-size: cover;
    font-family: Inter, Inter;
    font-weight: 500;
    font-size: 18px;
    color: #52405C;
    line-height: 56px;
    text-align: center;
  }
}
</style>
<template>
  <div class="box">
    <div class="title">只差一步领取福利</div>
    <img class="ewm" src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/gzhewm.jpg" alt="">

    <div class="tips">长按识别二维码关注公众号参与活动</div>
<!--    <div class="commBtn" @click="gzhFn">我已关注</div>-->
  </div>
</template>
<script>
import { postConfigApi } from '@/api/index.js'
export default {
  data() {
    return {}
  },
  created() {
  },

  mounted() {
    postConfigApi({
      sdfg: 'https://zking.jslzcf.com',
    }).then((res) => { });
  },

  methods: {
    gzhFn() {
      this.$emit('gzhFn')
    },

    gzhClickFn() {
      window.location.href = 'https://mp.weixin.qq.com/s/hRclFLx96jrTEeyDEVn3RQ'
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 22px;
    color: #000000;
    line-height: 28px;
  }

  .ewm {
    width: 200px;
    margin: 20px 0 15px 0;
    border-radius: 8px;
  }

  .tips {
    width: 198px;
    font-weight: 400;
    font-size: 12px;
    color: #808080;
  }

  .commBtn {
    font-size: 16px;
    color: #EE9000;
    text-align: center;
    margin-top: 30px;
  }
}

.gzh {
  width: 220px;
  background-color: #FAFAFA;

  .gzh-t {
    .gzh-t-l {}
  }
}
</style>

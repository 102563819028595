<template>
  <div class="box">
    <div class="top">
      <div class="noReward">
        <div>很遗憾未中奖</div>
        <div class="again">期待您下次再来～～</div>
      </div>
      <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/bc048784dfea3e2edaf981732bbd350.png" alt="">
    </div>
    <div class="commBtn" @click="againFn">继续摇奖</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  created() {
  },

  methods: {
    againFn() {
      this.$emit('rewarnFn', '1')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 0 0;

    img {
      width: 159px;
      margin-top: 30px;
    }

    .noReward {
      font-family: Inter, Inter;
      font-weight: bold;
      font-size: 24px;
      color: #000000;
      line-height: 28px;

      .again {
        font-weight: 400;
        font-size: 16px;
        color: #808080;
      }
    }
  }

  .commBtn {
    margin: 30px auto 30px;
    width: 230px;
    height: 56px;
    // padding:14px 0 0 136px ;
    box-sizing: border-box;
    background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/commBtn.png");
    background-size: cover;
    font-family: Inter, Inter;
    font-weight: 500;
    font-size: 18px;
    color: #52405C;
    line-height: 56px;
    text-align: center;
  }
}
</style>
<template>
  <div class="box">
    <div class="noReward">我的奖品</div>
    <template v-if="priceList.length != 0">
      <div class="tips">
        <div>奖品已发放至您的账户</div>
        <div>紫金保险公众号-个人中心-我的卡劵，查看使用</div>
      </div>
      <div class="priceH">
        <div class="rulesBox" v-for="(item, index) in priceList" :key="index">
          <div class="imgbox">
            <img :src="item.productDetail" alt="" mode="aspectFit" />
          </div>
          <div class="infoBox">
            <div>{{ item.productName }}
              <span v-if="item.nm > 1"> &nbsp;&nbsp;&nbsp;&nbsp;x {{ item.nm }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else>
      <div class="noPrice">
        <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/later2x.png" alt="">
        <div class="msg">暂无奖品</div>
      </div>
    </div>
    <!-- <div class="commBtn" @click="againFn">去使用</div> -->
  </div>
</template>
<script>
import { myPrice } from '@/api/index.js'
export default {
  data() {
    return {
      priceList: [
        // {
        //   productDetail: 'https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/product-pic/ff5d2f3c3f23713071fa89e38702324.png',
        //   productName: '视频会员周卡(腾讯、优酷、搜狐、芒果四选一)',
        // },
        // {
        //   productDetail:'https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/product-pic/zpbt.png',
        //   productName: '视频季卡'
        // },
        // {
        //   productDetail:'https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/product-pic/100%E5%85%83%E4%BF%9D%E5%85%BB%E5%8A%B5a.png',
        //   productName: '视频季卡'
        // },
      ],
    };
  },
  created() {
    this.getMyPrice()
  },

  methods: {
    // 我的奖品
    getMyPrice() {
      myPrice().then(res => {
        this.priceList = res.data
      })
    },

    againFn() {
      window.location.href = process.env.VUE_APP_ZKING_BASE_API + '/app/mall/avs-wxh5/#/PersonEquity?serviceType=6'
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  padding-bottom: 30px;
  .noReward {
    text-align: center;
    margin: 24px 0 0 0;
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 22px;
    color: #000000;
    line-height: 28px;
  }

  .priceH {
    max-height: 430px;
    overflow-y: auto;
  }

  .rulesBox {
    margin: 0 19px 6px 17px;
    background: #ffffff;
    border-radius: 7px 7px 7px 7px;
    padding: 7px 11px 7px 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .imgbox {
      width: 70px;
      height: 70px;
      background: #eee9ff;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .infoBox {
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      width: 200px;
    }
  }

  .commBtn {
    margin: 30px auto 30px;
    width: 230px;
    height: 56px;
    // padding:14px 0 0 136px ;
    box-sizing: border-box;
    background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/commBtn.png");
    background-size: cover;
    font-family: Inter, Inter;
    font-weight: 500;
    font-size: 18px;
    color: #52405c;
    line-height: 56px;
    text-align: center;
  }
}

.noPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;

  img {
    width: 200px;
  }

  .msg {
    font-size: 16px;
    color: #000000;
  }
}
.tips{
  text-align: center;
  // font-size: 16px;
  margin: 14px 0 12px 0;
}
</style>

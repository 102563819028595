import request from '@/utils/request'

var comid = 10


// 配置微信api
export function postConfigApi(params, cb) {
    return request({
        url: '/biz/auth/sssssxxx',
        method: 'get',
        params: params,
    })
        .then(res => {
            console.log('sssssxxxres', res);
            var retData = res.data.data;
            wx.config({
                debug: false,
                appId: "wxd0dbfed69fd5c110",
                timestamp: retData.timestamp,
                nonceStr: retData.nonceStr,
                signature: retData.signature,
                jsApiList: [
                    "chooseImage",
                    "previewImage",
                    "getLocalImgData",
                    "scanQRCode",
                ],
            });
            wx.error(function (res) { });
            wx.ready(function () {
                cb && cb();
            });
        })
}

// 登录
export function loginApi(params) {
    return request({
        url: '/biz/auth/login',
        method: 'post',
        data: params,
        qsFlag: false
    })
        .then(res => res.data)
}

// 活动信息
export function getActApi(params) {
    return request({
        url: '/biz/act/detail/' + comid,
        method: 'get',
        params: params,
    })
        .then(res => res.data)
}

// 个人信息
export function getPersonApi(params) {
    return request({
        url: '/biz/auth/profile',
        method: 'post',
        data: params,
        qsFlag: false
    })
        .then(res => res.data)
}

// 奖品信息
export function getProApi(params) {
    return request({
        url: '/biz/product/detail/' + comid,
        method: 'get',
        params: params,
    })
        .then(res => res.data)
}

// 埋点信息
export function trackingMonitor(eventTrackingEnum) {
    return request({
        url: '/biz/event/tracking/monitor/' + eventTrackingEnum,
        method: 'get',
    })
        .then(res => res.data)
}

// 我的奖品
export function myPrice() {
    return request({
        url: '/biz/auth/my/draw/product',
        method: 'get',
    })
        .then(res => res.data)
}

// 抽奖
export function drawApi(type) {
    return request({
        url: '/biz/draw/draw/' + comid + '/' + type + '?bid=' + localStorage.getItem('Authorization'),
        method: 'post',
        qsFlag: false
    })
        .then(res => res.data)
}

// 滚动的获奖信息
export function drawrdProduct() {
    return request({
        url: '/biz/draw/drawrd/product',
        method: 'get',
    })
        .then(res => res.data)
}

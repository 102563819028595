<template>
  <div class="box">
    <div class="noReward">
      <div>太幸运了吧</div>
      <div class="again">恭喜中奖 {{ luckPriceData.length }} 份礼品</div>
      <div class="imgBox">
        <div class="li" :style="'width: ' + getWidth()" v-for="(item, index) in luckPriceData" :key="index">
          <div class="img">
            <img :src="item.productDetail" alt="">
          </div>
          <div class="name">{{ item.productName }}</div>
        </div>
      </div>
    </div>
    <div class="commBtn" @click="receiveFn">立即领取</div>
  </div>
</template>
<script>
export default {
  props: {
    luckPriceData: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {}
  },
  created() { },

  methods: {
    receiveFn() {
      this.$emit('rewarnFn', 'lqjp')
    },
    getWidth() {
      return (100 / this.luckPriceData.length) + '%'
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  .addCalendar {
    position: relative;

    img {
      width: 159px;
      height: 159px;
      position: absolute;
      top: 0;
    }
  }

  .noReward {
    margin: 50px 0 27px 0px;
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .again {
      font-weight: 400;
      font-size: 16px;
      color: #808080;
      margin: 5px 0 24px 0;
    }

    .imgBox {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 0 10px;
      box-sizing: border-box;

      .li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;
        text-align: center;

        .img{
          width: 80px;
          height: 88px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
          }
        }

        .name {
          width: 100%;
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: normal;
        }
      }

    }
  }

  .commBtn {
    margin: 30px auto 30px;
    width: 230px;
    height: 56px;
    // padding:14px 0 0 136px ;
    box-sizing: border-box;
    background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/commBtn.png");
    background-size: cover;
    font-family: Inter, Inter;
    font-weight: 500;
    font-size: 18px;
    color: #52405C;
    line-height: 56px;
    text-align: center;
  }
}
</style>
<template>
  <div class="home">
    <!-- 紫金保险 -->
    <div class="topZjbx">
      <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/zjbx2x.png" alt="">
    </div>
    <!-- 摇钱树 -->
    <div class="treeTitle">
      <div class="treeNameBox">

        <!-- 活动开始显示     中奖名单-->
        <div v-if="actType == '09'">
          <van-swipe vertical :autoplay="3000" class="reward" v-if="rewardList.length > 0">
            <van-swipe-item v-for="(item, key) in rewardList " :key="key">{{ item }}</van-swipe-item>
            <template #indicator>
              <div class="rewardIndicator"></div>
            </template>
          </van-swipe>
        </div>

        <div class="activeName">-2024年紫金保险客户节-</div>
        <div class="lucyTree">幸运摇钱树</div>

        <!-- 预热阶段显示   开奖时间-抽奖提示 -->
        <div class="expectHotBox" v-if="actType == '01'">
          <div class="timeBox">开摇时间：{{ rewardTime }}</div>
          <div class="rewardChange">
            尊敬的紫金客户，您在紫金累计投保 {{ insureNm }} 年，得 <div class="count">{{ totalDrawNm }}</div> 次抽奖机会。
          </div>
        </div>

        <!-- 活动开始显示  -->
        <div class="tips" v-if="actType == '09'">
          <div class="tipsContent">
            您在紫金累计投保 {{ insureNm }} 年，共得&nbsp;<div class="count">{{ totalDrawNm }}</div>&nbsp;次抽奖机会
          </div>
        </div>
      </div>

      <!-- 活动开始显示  规则-产品-->
      <div class="rule" v-if="actType == '09'">
        <div class="ruleBg" @click="ruleAndMypriceFn('rule')">活动规则</div>
        <div class="ruleBg ruleBgMar" @click="ruleAndMypriceFn('myprice')">我的奖品</div>
      </div>
    </div>

    <!-- 摇钱树背景图片 -->
    <div style="width:100%;height:330px" class="treeBg">
      <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/518-tree.gif" alt=""
        style="width:100%;height:330px" v-if="isGif">
      <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/treePng2.png" alt=""
        style="width:100%;height:330px" v-else>
    </div>

    <!--活动开始阶段显示  摇一摇 -->
    <div :class="actType == '09' ? 'purpleBg' : 'purpleBg btnBox'">
      <template v-if="actType == '09'">
        <div class="callBtn" style="margin-bottom:10px" @click="yyy()">
          <div>点击摇奖</div>
          <div class="changeCount">还有 <span>{{ drawNm }}</span> 次机会</div>
          <div class="dot">{{ drawNm }}</div>
        </div>
        <div class="threeBox">
          <van-checkbox v-model="checked" :disabled="drawNm < 3">
            <span class="three">三次连摇</span>
          </van-checkbox>
        </div>
      </template>

      <!-- 开摇倒计时 -->
      <div class="countdownbox" v-if="actType == '01'">
        <div class="countdown">
          距开摇
          <van-count-down :time="time" format="DD天 HH:mm:ss">
            <template #default="timeData">
              <span class="block">{{ timeData.days > 9 ? timeData.days : '0' + timeData.days }}</span>
              <span class="day">天</span>
              <span class="block">{{ timeData.hours > 9 ? timeData.hours : '0' + timeData.hours }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.minutes > 9 ? timeData.minutes : '0' + timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds > 9 ? timeData.seconds : '0' + timeData.seconds }}</span>
            </template>
          </van-count-down>
        </div>
      </div>

      <!-- 进入紫金时光机 -->
      <div class="purpleBtn" @click="goSgj">
        <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/time2x.png" alt="" class="timeBoxImg">
        <div>
          <div>想知道自己投保的那些年？</div>
          <div class="enter">
            <div>进入紫金时光机</div>
            <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/arrow2x.png" alt="">
          </div>
        </div>
      </div>

      <!-- 添加日历提醒 -->
      <!-- <div class="purpleBtn calendarBtn" v-if="actType == '01'" @click="showCalender(1)">
        <div class="addBox">
          <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/calendar2x.png" alt="">
          <div >添加提醒</div>
        </div>
        <div>5月17日 9:00</div>
      </div> -->

      <!-- 奖品swipe -->
      <div class="cont-lb">
        <van-swipe :autoplay="3000" :lazy-render="true">
          <van-swipe-item v-for="(itemlist, key) in priceList " :key="key">
            <div v-for="(item, index) in itemlist" :key="index" class="img-class">
              <div class="imgbox">
                <img :src="item.productDetail" alt>
              </div>
              <div class="productName">{{ item.productName }}</div>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="rewardIndicator"></div>
          </template>
        </van-swipe>
      </div>

      <!-- 特惠产品 -->
      <div class="productBox">
        <div class="productTitle">爆款产品推荐</div>
        <div v-for="(item, index) in productList" :key="index" class="list" @click="proDetail(item, index)">
          <div class="productImg">
            <img :src="item.img" alt="">
          </div>
          <div class="infoBox">
            <div class="infoHidden">{{ item.title }}</div>
            <div class="info infoHidden">{{ item.info }}</div>
            <div class="priceBox">
              <div>￥{{ item.price }} <span style="font-size: 12px;color: #505050;">元起</span></div>
              <div class="detailBtn">
                <img v-if="item.hasHand" class="hand"
                  src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/20240508191429.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="bottomFooter">
          <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/footer@2x.png" alt="">
        </div>
      </div>

      <div class="bgt"></div>
    </div>

    <van-popup v-model="pricePopup.show" :close-on-click-overlay="false">
      <!-- 很遗憾未中奖 -->
      <noPrice @rewarnFn="rewarnFn" v-if="pricePopup.noPriceShow" />

      <!-- 活动太火爆了，请稍后再来 -->
      <laterAgain :laterAgainMsg="pricePopup.laterAgainMsg" @rewarnFn="rewarnFn" v-if="pricePopup.laterAgainShow" />

      <!-- 中奖（一件奖品/多件奖品） -->
      <luckPrice :luckPriceData="pricePopup.luckPriceData" @rewarnFn="rewarnFn" v-if="pricePopup.luckPriceShow" />

      <!-- 添加日历 -->
      <addCalender @rewarnFn="rewarnFn" v-if="pricePopup.addCalenderShow" />
    </van-popup>

    <!-- 关注公众号 -->
    <van-popup v-model="gzhPopupShow" :close-on-click-overlay="false" class="gzhPopup">
      <gzh @gzhFn="gzhFn" />
    </van-popup>

    <van-popup v-model="rulePricePopup.show" :close-on-click-overlay="true" @close="rulePricePopupCloseFn"
      class="rulePricePopup">
      <!--活动规则 -->
      <activityRules @rewarnFn="rewarnFn" v-if="rulePricePopup.activityRulesshow" />
      <!--我的奖品 -->
      <myPrize @rewarnFn="rewarnFn" v-if="rulePricePopup.myPrizeshow" />
    </van-popup>

  </div>
</template>

<script>
import wx from "weixin-js-sdk"
import { loginApi, getActApi, getPersonApi, getProApi, trackingMonitor, drawApi, drawrdProduct } from '@/api/index.js'
import laterAgain from '../components/laterAgain.vue';
import noPrice from '../components/noPrice.vue';
import luckPrice from '../components/luckPrice.vue';
import addCalender from '../components/addCalender.vue';
import activityRules from '../components/activityRules.vue';
import myPrize from '../components/myPrize.vue';
import gzh from '../components/gzh.vue';
export default {
  components: { noPrice, laterAgain, luckPrice, addCalender, activityRules, myPrize, gzh },
  data() {
    return {
      actType: null, //活动类型 01预热 09开始 -1结束
      rewardTime: '2024年5月17日 9:00',  //开奖时间
      drawNm: null, //抽奖次数
      insureNm: null, //投保年限
      totalDrawNm: null, //累计抽奖次数
      rewardList: [],

      checked: false, //三次连摇
      isClik: true, //摇一摇按钮是否可点击
      pricePopup: {
        show: false,  //弹出框
        laterAgainShow: false, //活动太火爆弹框
        laterAgainMsg: null, //错误提示
        noPriceShow: false, //未中奖弹框
        luckPriceShow: false, //获取奖品弹框
        luckPriceData: [], //奖品列表
        addCalenderShow: false, //添加日历成功提醒
      },
      gzhPopupShow: false, //公众号弹框
      rulePricePopup: {
        show: false,
        activityRulesshow: false, //活动规则弹窗
        myPrizeshow: false, //我的奖品弹窗
      },
      isGif: false,  //是否动图
      productList: [
        {
          img: 'https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/imgbwyl.jpg',
          title: "无忧保百万医疗保险（互联网版）",
          info: '保障全，保费低',
          price: '171',
          code: 'TOP_PRODUCT1',
          path: null,
          hasHand: false,
          id: 1,
        },
        // {
        //   img: 'https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/imgaxjb.jpg',
        //   title: "安心酒保（代驾版）",
        //   info: '喝酒人士必备，滴滴代驾服务，超高性价比',
        //   price: '119',
        //   code: 'TOP_PRODUCT1',
        //   path: null,
        //   hasHand: false,
        //   id: 1,
        // },
        {
          img: 'https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/imgzxak.jpg',
          title: '"紫享安康"恶性肿瘤医疗险',
          info: '保障权 保费低 新型CAR-T疗法，特药0免赔',
          price: '56.2',
          code: 'TOP_PRODUCT2',
          path: null,
          hasHand: false,
          id: 2,
        },
        // {
        //   img: 'https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/imgcyb.png',
        //   title: '畅影保',
        //   info: '绿色出行畅享浪漫影程',
        //   price: '25',
        //   code: 'TOP_PRODUCT2',
        //   path: null,
        //   hasHand: false,
        //   id: 3,
        // },
      ],

      priceList: [],

      sgjPath: null,

      time: null
    }
  },
  created() {
    // 判断链接中是否含有token
    if (this.$route.query.return_zking_info) {
      this.login()
    } else {
      if (localStorage.getItem('Authorization')) {
        // 已登录状态 请求活动信息、个人信息
        this.getActData()
        this.getPersonData()
        this.getPro()
        this.getDrawrdProduct()
        this.getCountdownTime()
      } else {
        window.location.href = process.env.VUE_APP_ZKING_BASE_API + '/app/sso-passport-authenticate/wecom/activityWxCode.html?wServerName=GW_KHJ2024&returnUrl=' + process.env.VUE_APP_ZKING_INDEX_PAGE
      }
    }
  },

  methods: {
    getCountdownTime() {
      var currentTime = new Date();
      var specificTime = new Date('May 17, 2024 09:00:00');
      this.time = specificTime - currentTime;
    },

    login() {
      loginApi({
        utoken: this.$route.query.return_zking_info,
      }).then((res) => {
        localStorage.setItem('Authorization', res.data.accessToken)
        this.getActData()
        this.getPersonData()
        this.getPro()
        this.getDrawrdProduct()
        this.getCountdownTime()
      });
    },

    // 获取活动信息
    getActData() {
      getActApi().then((res) => {
        this.actType = res.data
        if (this.actType == '01') {
          trackingMonitor('YR_PAGE').then((res) => { });
        } else if (this.actType == '09') {
          trackingMonitor('DRAW_PAGE').then((res) => { });
        }
      });
    },

    // 获取滚动的获奖信息
    getDrawrdProduct() {
      drawrdProduct().then((res) => {
        this.rewardList = []
        res.data.forEach(element => {
          this.rewardList.push('刚刚' + element + '摇到一份奖品，运气不错')
        });
      });
    },

    // 获取投保年限和摇奖次数
    getPersonData() {
      getPersonApi().then((res) => {
        if (res.code == 1006000000) {
          // 关注公众号
          this.gzhPopupShow = true
        } else {
          this.gzhPopupShow = false

          this.drawNm = res.data.drawNm
          this.insureNm = res.data.insureNm
          this.totalDrawNm = res.data.totalDrawNm

          if (this.drawNm < 3){
            this.checked = false;
          }
          // 如果抽奖次数大于3，选中状态
          // if (this.drawNm >= 3) {
          //   this.checked = true;
          // } else {
          //   this.checked = false;
          // }

          this.sgjPath = process.env.VUE_APP_ZKING_BASE_API + '/app/open/operation-h5/#/timePlus?token=' + res.data.zkToken + '&phone=' + res.data.mobile

          this.productList.forEach(item => {
            if (item.id == 1) {
              // item.path = process.env.VUE_APP_ZKING_BASE_API + '/app/b2c/#/particulars/0499-qg-grzrdjb/1/?isWho=Client&isSalesmanSharing=Salesman&productCode=0499-qg-grzrdjb&magicId=1&comboCode=0499-qg-grzrdjb-01&optionalFlag=0&channelCode=ZKICATM&accountType=5&introduceName=%E5%88%98%E4%BC%9F&agentCode=232013324&comCode=2320101100&businessAccountType=3&businessUserCode=232013324&networkCode=GW_KHJ2024&bosActId=10&bosActName=518%E5%AE%A2%E6%88%B7%E8%8A%82%E7%BA%BF%E4%B8%8A%E6%B4%BB%E5%8A%A8%EF%BC%882024%EF%BC%89&bosActFrom=%E5%BE%AE%E4%BF%A1%E5%B0%8F%E7%A8%8B%E5%BA%8F&token=' + res.data.zkToken
              item.path = process.env.VUE_APP_ZKING_BASE_API + '/app/b2c/#/particulars/0666/1/?channelCode=DP_OWXPA&terminalId=H5&isWho=Client&accountType=5&recomName=&recomIdentifyNum=&areaFlag=9&productCode=0666&networkCode=GW_KHJ2024&bosActId=10&bosActName=518%E5%AE%A2%E6%88%B7%E8%8A%82%E7%BA%BF%E4%B8%8A%E6%B4%BB%E5%8A%A8%EF%BC%882024%EF%BC%89&bosActFrom=%E5%BE%AE%E4%BF%A1%E5%B0%8F%E7%A8%8B%E5%BA%8F&token=' + res.data.zkToken
            }
            if (item.id == 2) {
              item.path = process.env.VUE_APP_ZKING_BASE_API + '/app/b2c/#/particulars/060I-ZXAK-EXZLYL/1/?channelCode=DP_OWXPA&professionBackFlag=true&terminalId=H5&isWho=Client&accountType=5&recomName=undefined&recomIdentifyNum=undefined&productCode=060I-ZXAK-EXZLYL&networkCode=GW_KHJ2024&bosActId=10&bosActName=518%E5%AE%A2%E6%88%B7%E8%8A%82%E7%BA%BF%E4%B8%8A%E6%B4%BB%E5%8A%A8%EF%BC%882024%EF%BC%89&bosActFrom=%E5%BE%AE%E4%BF%A1%E5%B0%8F%E7%A8%8B%E5%BA%8F&token=' + res.data.zkToken
            }
            // if (item.id == 3) {
            //   item.path = process.env.VUE_APP_ZKING_BASE_API + '/app/b2c/#/particulars/0436-FJDC-CYB/1/?isWho=Client&isSalesmanSharing=Salesman&productCode=0436-FJDC-CYB&magicId=1&comboCode=0436-FJDC-CYB-01&optionalFlag=0&channelCode=DP_ZYB&accountType=5&introduceName=%E5%88%98%E4%BC%9F&agentCode=232013324&comCode=2320101100&businessAccountType=3&businessUserCode=232013324&networkCode=GW_KHJ2024&bosActId=10&bosActName=518%E5%AE%A2%E6%88%B7%E8%8A%82%E7%BA%BF%E4%B8%8A%E6%B4%BB%E5%8A%A8%EF%BC%882024%EF%BC%89&bosActFrom=%E5%BE%AE%E4%BF%A1%E5%B0%8F%E7%A8%8B%E5%BA%8F&token=' + res.data.zkToken
            // }
          })
        }
      });
    },

    // 点击公众号文字 我已关注
    gzhFn() {
      this.getPersonData()
    },

    // 获取奖品轮播图
    getPro() {
      getProApi().then((res) => {
        this.arrayResolve(3, res.data); // 处理奖品轮播图
      });
    },
    arrayResolve(n, arr) {
      //需要补齐的length
      const length = 3 - arr.length % 3;
      for (let i = 0; i < length; i++) {
        arr.push(arr[i]);
      }
      this.priceList = []
      for (let i = 0; i < arr.length; i += n) {
        this.priceList.push(arr.slice(i, i + n));
      }
    },

    // 点击进入时光机
    goSgj() {
      trackingMonitor('ZKING_SGJ').then((res) => {
        // wx.miniProgram.navigateTo({ url: '/pages/handletowebview/handletowebview?jumpParams=zkingp011' })
        window.location.href = this.sgjPath
      });
    },

    // 点击我的奖品和 规则
    ruleAndMypriceFn(type) {
      this.rulePricePopup.show = true
      if (type == 'rule') {
        this.rulePricePopup.activityRulesshow = true
      } else {
        this.rulePricePopup.myPrizeshow = true
      }

    },

    // type --- 1:继续摇奖   2:立即领取  3:活动太火爆了，请稍后再来  4: 添加日历
    rewarnFn(type) {
      this.pricePopup.show = false;
      this.pricePopup.addCalenderShow = false;
      this.pricePopup.luckPriceShow = false;
      this.pricePopup.noPriceShow = false;
      this.pricePopup.laterAgainShow = false;

      this.rulePricePopup.show = false;
      this.rulePricePopup.activityRulesshow = false;
      this.rulePricePopup.myPrizeshow = false;

      // if (type == 'lqjp') {
      //   this.rulePricePopup.show = true
      //   this.rulePricePopup.myPrizeshow = true
      // }
    },

    rulePricePopupCloseFn() {
      this.rulePricePopup.show = false;
      this.rulePricePopup.activityRulesshow = false;
      this.rulePricePopup.myPrizeshow = false;
    },

    // 点击/摇一摇 ---抽奖
    yyy() {
      if (!this.isClik) {
        return
      }
      if (this.drawNm < 1) {
        this.$toast('摇奖次数已经用光啦')
        return
      }
      var type = this.checked ? 'T' : 'O'
      this.isGif = true;
      this.isClik = false
      drawApi(type).then((res) => {
        setTimeout(() => {
          this.isGif = false;
          this.isClik = true
          this.getPersonData()
          this.pricePopup.show = true;
          if (res.code == 0) {
            // 抽奖成功 返回data 奖品列表 空为未中奖
            if (res.data.length == 0) {
              this.pricePopup.noPriceShow = true
            } else {
              this.pricePopup.luckPriceData = res.data
              this.pricePopup.luckPriceShow = true
            }
          } else if (res.code == 1004100000) {
            this.pricePopup.laterAgainShow = true
            this.pricePopup.laterAgainMsg = '活动太火爆了，请稍后再来'
          } else if (res.code == 1004000001) {
            this.pricePopup.laterAgainShow = true
            this.pricePopup.laterAgainMsg = '抽奖次数用光啦\n到特惠产品区看看吧'
          } else if (res.code == 1004000003) {
            this.pricePopup.laterAgainShow = true
            this.pricePopup.laterAgainMsg = '抽奖次数已不足\n请取消三次连摇'
          } else {
            this.pricePopup.laterAgainShow = true
            this.pricePopup.laterAgainMsg = res.msg
          }
        }, 3000)
      });
      trackingMonitor('DRAW_CLICK').then((res) => { });
    },


    showCalender() {
      this.pricePopup.show = true;
      this.pricePopup.addCalenderShow = true
    },


    // 跳转产品 详情
    proDetail(item, index) {
      let obj = { ...item }
      obj.hasHand = true
      this.$set(this.productList, index, obj)
      trackingMonitor(item.code).then((res) => {
        setTimeout(() => {
          window.location.href = item.path
          obj.hasHand = false
          this.$set(this.productList, index, obj)
        }, 500)
      });
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;


  .rewardIndicator {
    height: 0;
    width: 0;
  }

  .topZjbx {
    background: #E8DECC;
    padding: 8px 0 0 11px;

    img {
      width: 121px;
      // height: 23px;
    }
  }

  .treeTitle {
    background: #E8DECC;
    padding: 10px 0 0 0;
    position: relative;

    .treeNameBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: Inter, Inter;
      font-weight: 500;
      font-size: 16px;
      color: #8D48B5;
      font-style: normal;

      .activeName {
        letter-spacing: 2px;
      }

      .lucyTree {
        font-size: 34px;
        color: #914FB7;
        font-weight: bold;
        letter-spacing: 1px;
      }

      .reward {
        width: 228px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background: rgba(135, 34, 193, 0.58);
        border-radius: 6px 6px 6px 6px;
        font-family: Inter, Inter;
        font-weight: 400;
        font-size: 11px;
        color: #FFFFFF;
        margin-bottom: 10px;

      }

      .expectHotBox {
        margin-top: 5px;
        height: 113px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .timeBox {
          width: 254px;
          height: 32px;
          line-height: 32px;
          background: linear-gradient(90deg, #8F24D4 0%, #6039CF 100%);
          border: 1px solid #FFFFFF;
          border-radius: 33px;
          text-align: center;
          font-family: Inter, Inter;
          font-weight: 400;
          font-size: 16px;
          color: #FFFFFF;
          z-index: 111;
        }

        .rewardChange {
          top: 16px;
          width: 326px;
          height: 97px;
          line-height: 25px;
          box-sizing: border-box;
          padding: 28px 0 0 21px;
          background: linear-gradient(263deg, #C880EA 0%, #8560DB 100%);
          box-shadow: 0px 4px 5px 0px rgba(152, 124, 212, 0.73);
          border-radius: 10px 10px 10px 10px;
          border: 1px solid #FFFFFF;
          position: absolute;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;

          // display: flex;
          // align-items: center;
          .count {
            display: inline-block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: #FE4D4F;
            border-radius: 100%;
          }
        }
      }
    }

    .rule {
      position: absolute;
      top: 6px;
      right: 0px;

      .ruleBg {
        width: 70px;
        padding-left: 11px;
        box-sizing: border-box;
        height: 34px;
        line-height: 34px;
        background: rgba(128, 47, 157, 0.92);
        border-radius: 33px 0 0 33px;
        border: 1px solid #FFFFFF;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
      }

      .ruleBgMar {
        margin-top: 3px;
      }
    }
  }

  .treeBg {
    width: 100%;
    height: 300px;

    img {
      width: 100%;
      height: 300px
    }
  }

  .btnBox {
    padding-top: 34px;
  }

  .purpleBg {
    background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/purperbg.png");
    background-size: cover;
    position: relative;
    width: 100%;
    top: -45px;
    z-index: 1111;
    box-sizing: border-box;
    // padding: 0 20px 0 20px;
    padding-right: 20px;
    padding-left: 20px;

    .callBtn {
      width: 92%;
      height: 92px;
      background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/callBg2x.png");
      background-size: 100% 100%;
      // background-size: cover;
      padding: 34px 0px 0 113px;
      box-sizing: border-box;
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 16px;
      color: #52405C;
      position: relative;
      margin: 0 auto;

      .changeCount {
        font-size: 14px;

        // margin-top: 6px;
        span {
          font-weight: normal;
          font-size: 18px;
          color: #FE4D4F;
        }
      }

      .dot {
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        background: #FE4D4F;
        border-radius: 100%;
        position: absolute;
        top: 17px;
        right: 13px;
        font-family: Inter, Inter;
        font-weight: 400;
        font-size: 17px;
        color: #FFFFFF;
      }
    }

    .threeBox {
      display: flex;
      justify-content: end;
      margin-bottom: 10px;

      ::v-deep .van-checkbox__icon--checked .van-icon {
        background: linear-gradient(180deg, #FFD73D 0%, #FFBF1F 100%) !important;
        border: #FFD73D;
        color: #52405C;
      }

      ::v-deep.van-checkbox__icon .van-icon {
        background: rgba(255, 255, 255, 0.46);
        border-radius: 100%;
        border: 1px solid #FFFFFF;
      }

      .three {
        font-family: Inter, Inter;
        font-weight: 400;
        font-size: 16px;
        color: #FFCA2D;
      }
    }

    .purpleBtn {
      width: 92%;
      height: 66px;
      padding: 12px 0 0 110px;
      box-sizing: border-box;
      background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/btnBg.png");
      background-size: 100% 100%;
      // background-size: cover;
      position: relative;
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #52405C;
      text-transform: none;
      margin: 0 auto;

      .timeBoxImg {
        width: 90px;
        height: 91px;
        position: absolute;
        top: -16px;
        left: 11px;
      }

      .enter {
        font-family: Inter, Inter;
        font-weight: 500;
        font-size: 16px;
        margin-top: 3px;
        display: flex;
        align-items: center;
        margin-top: 5px;

        img {
          width: 16px;
          height: 13px;
          margin-left: 7px;
        }
      }
    }

    .calendarBtn {
      margin-top: 19px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 9px 0;
      font-size: 20px;
      line-height: 23px;

      .addBox {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        img {
          width: 28px;
          height: 28px;
        }
      }
    }

    .cont-lb {
      margin-top: 15px;

      /deep/ .van-swipe__indicator {
        background: #bbc0c7;
      }

      /deep/.van-swipe__indicator--active {
        background: #58fa0b;
      }

      // display: flex;
    }

    .img-class {
      background: rgba(35, 35, 35, 0.57);
      border-radius: 3px 3px 3px 3px;
      width: 30%;
      box-shadow: 0px 2px 12px rgba(100, 101, 102, 0.12);
      border-radius: 5px;
      // border: 0.75px solid #EDEEEF;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      float: left;
      margin-left: 9px;
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      padding: 8px 5px;
      box-sizing: border-box;

      .imgbox {
        width: 80%;
        height: 76px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
        }
      }

      .productName {
        // margin-bottom: 13px;
        text-align: center;
        // position: absolute;
        // margin-left: 10.5px;
        // margin-top: 3px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

  }

  .productBox {
    margin-top: 20px;
    border-radius: 6px 6px 6px 6px;
    background-color: #fff;
    padding: 15px 12px 18px 15px;

    .productTitle {
      font-family: Inter, Inter;
      font-weight: bold;
      font-size: 16px;
      color: #52405C;
      line-height: 19px;
      padding-bottom: 11px;
    }

    .list {
      border-bottom: 1px solid #F3F3F3;
      display: flex;
      margin-top: 11px;
      height: 100px;

      .productImg {
        width: 91px;
        height: 84px;
        margin-right: 14px;

        img {
          width: 91px;
          height: 84px;
          border-radius: 6px;
        }
      }

      .infoBox {
        font-size: 12px;
        color: #303030;
        width: 210px;

        .infoHidden {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .info {
          margin-top: 10px;
          font-size: 10px;
          color: #999;

        }

        .priceBox {
          margin-top: 25px;
          font-weight: 500;
          font-size: 16px;
          color: #7E3FEB;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .detailBtn {
            width: 80px;
            height: 27px;
            // line-height: 24px;
            // text-align: center;
            // background: linear-gradient(180deg, #FFB181 0%, #EE7C66 100%);
            // border-radius: 13px 13px 13px 13px;
            // border: 2px solid #FFB181;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
            background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/20240508191438.png");
            background-size: 100% 100%;
            position: relative;

            .hand {
              position: absolute;
              top: 10px;
              right: 10px;
              width: 20px;
            }
          }
        }
      }
    }

    .bottomFooter {
      margin-top: 15px;
      width: 100%;
      height: 21px;
      display: flex;
      justify-content: center;

      img {
        width: 238px;
        height: 21px;
      }
    }
  }

  .bgt {
    text-align: center;
    padding: 15px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
  }

  .van-popup {
    width: 297px;
    // height: 300px;
    border-radius: 14px 14px 14px 14px;
    background: linear-gradient(180deg, #FFFDF4 0%, #FFF6D4 100%);
  }

  .addCalendar {
    position: relative;

    img {
      width: 159px;
      height: 159px;
      position: absolute;
      top: -50px;
    }
  }

  .noReward {
    margin: 120px 0 80px 74px;
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    line-height: 28px;

    .again {
      font-weight: 400;
      font-size: 16px;
      color: #808080;
    }
  }

  .commBtn {
    margin: 0 auto 49px;
    width: 230px;
    height: 56px;
    // padding:14px 0 0 136px ;
    box-sizing: border-box;
    background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/commBtn.png");
    background-size: cover;
    font-family: Inter, Inter;
    font-weight: 500;
    font-size: 18px;
    color: #52405C;
    line-height: 56px;
    text-align: center;
  }

  .van-popup {
    overflow: inherit;
  }
}

.rulePricePopup {
  width: 340px !important;
  border-radius: 14px 14px 14px 14px;
  background: linear-gradient(180deg, #fffdf4 0%, #fff6d4 100%);
}

.gzhPopup {
  top: 45%;
}

.countdownbox {
  width: 100%;
  position: absolute;
  top: -20px;
  left: 0;
  display: flex;
  justify-content: center;

  .countdown {
    width: 240px;
    box-sizing: border-box;
    background: rgba(133, 96, 219, 0.6);
    padding: 6px 0;
    border-radius: 6px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 14px;
    color: #fff;
    vertical-align: bottom;
  }
}

::v-deep .van-count-down {
  font-size: 23px;
  color: #fff;
  margin-left: 14px;
  margin-top: 3px;

  .day {
    font-size: 14px;
    margin: 0 6px 0 2px;
  }
}

.tips {
  width: 100%;
  // position: absolute;
  // top: -35px;
  // left: 0;
  display: flex;
  justify-content: center;
  margin-top: 5px;

  .tipsContent {
    width: 305px;
    box-sizing: border-box;
    padding: 6px 0;
    background: rgba(133, 96, 219, 0.6);
    border-radius: 10px 10px 10px 10px;
    // border: 1px solid #FFFFFF;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    .count {
      display: inline-block;
      width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      background: #FE4D4F;
      border-radius: 100%;
    }
  }
}
</style>

<template>
  <div class="box">
    <div class="noReward">活动规则</div>
    <div class="rulesBox" v-for="(item, index) in activeList" :key="index">
      <div class="time">{{ item.title }}</div>
      <div class="content" v-for="itemChild in item.info">{{ itemChild }}</div>
    </div>
    <div class="commBtn" @click="againFn">确定</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeList: [
        {
          title: "活动时间",
          info: ["2024年5月17日 09:00 至 2024年6月30日 24:00"],
        },
        {
          title: "活动对象",
          info: ["紫金保险官微会员"],
        },
        {
          title: "活动说明",
          info: [
            "1.活动有效期内，紫金保险公众号会员每天可通过摇动摇钱树进行抽奖，摇奖次数以页面显示为准",
            "2.摇奖次数仅活动期间内有效",
            "3.请在有效期内兑换或者使用奖品，过期将作废",
            "4.在法律允许范围内，本活动最终解释权归属紫金保险所有",
          ],
        },
      ],
    };
  },
  created() { },

  methods: {
    againFn() {
      this.$emit("rewarnFn", "5");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .addCalendar {
    position: relative;

    img {
      width: 159px;
      height: 159px;
      position: absolute;
      top: 0;
    }
  }

  .noReward {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 29px 0 22px 0;
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 22px;
    color: #000000;
    line-height: 28px;
  }

  .rulesBox {
    padding: 0 46px 11px 27px;
    font-weight: 400;
    font-size: 16px;
    color: #808080;

    .time {
      font-family: Inter, Inter;
      font-weight: 500;
      font-size: 16px;
      color: #202020;
      margin-bottom: 10px;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      text-align: justify;
      // white-space: pre-wrap;
    }
  }

  .commBtn {
    margin: 30px auto 49px;
    width: 230px;
    height: 56px;
    // padding:14px 0 0 136px ;
    box-sizing: border-box;
    background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/commBtn.png");
    background-size: cover;
    font-family: Inter, Inter;
    font-weight: 500;
    font-size: 18px;
    color: #52405c;
    line-height: 56px;
    text-align: center;
  }
}
</style>

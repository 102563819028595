<template>
  <div class="box">
    <div class="noReward">
      <img src="https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/addRl.png" alt="">
      <div>添加日历提醒成功</div>
      <div class="info">开抢前10分钟将收到手机日历提醒，可在系统日历中更改提醒时间</div>
    </div>
    <div class="commBtn" @click="receiveFn">好的</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  created() {
  },

  methods: {
    receiveFn(){
      this.$emit('rewarnFn','4')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  .noReward{
    margin: 109px 0 28px 0px;
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img{
      width: 159px;
      height: 159px;
      position: absolute;
      top: -169px;
    }
    .info{
      padding: 0 28px;
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 16px;
      color: #808080;
      margin-top: 10px;
    }
  }
  .commBtn{
    margin: 0 auto 49px;
    width: 230px;
    height: 56px;
    // padding:14px 0 0 136px ;
    box-sizing: border-box;
    background-image: url("https://zj518-1325888264.cos.ap-nanjing.myqcloud.com/commBtn.png");
    background-size: cover;
    font-family: Inter, Inter;
    font-weight: 500;
    font-size: 18px;
    color: #52405C;
    line-height: 56px;
    text-align: center;
  }
}
</style>
import axios from 'axios';
import qs from 'qs'; // 处理数据格式


const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: true,
    crossDomain: true,
})

// request interceptor
request.interceptors.request.use(
    config => {
        if (localStorage.getItem('Authorization')) {
            config.headers['Authorization'] = localStorage.getItem('Authorization')
        }
        if (config.method === 'get') { // get请求直接放过
            return config;
        }
        if (config.method === 'post' && config.qsFlag) { // post请求判断是否加了数据处理标志
            // POST传参序列化(添加请求拦截器)
            config.data = qs.stringify(config.data);
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// TODO 401 未登录 全局拦截
// 响应拦截器
request.interceptors.response.use(res => {
    if (res.data.code == 401) {
        window.location.href = process.env.VUE_APP_ZKING_BASE_API + '/app/sso-passport-authenticate/wecom/activityWxCode.html?wServerName=GW_KHJ2024&returnUrl=https://zking.jslzcf.com'
    } else {
        return res
    }
},
    error => { return Promise.reject(error) })


export default request
